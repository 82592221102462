<template>
  <div class="w-full">
    <div
      v-if="loading"
      class="w-full h-80"
      v-loading="loading"
    ></div>
    <el-collapse v-else v-model="activeMenu" accordion>
      <el-collapse-item
        v-for="(group, i) in list"
        :key="group"
        :name="i+1"
      >
        <template #title>
          <div class="w-full text-blue-500">
            <span class="text-base uppercase">{{ group.name }}</span>
          </div>
        </template>
        <div>
          <div v-for="item in group.permissions" :key="item.name">
            <div class="flex justify-between bg-blue-50 p-2 mb-2 rounded-md">
              <div class="flex flex-col justify-center">
                <span class="text-sm font-medium text-blue-20">{{ item.readable_name }}</span>
              </div>
              <el-switch
                @click="actionPermission(item)"
                v-model="item.status"
                size="small"
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                :active-value="1"
                :inactive-value="0"
                :disabled="item.id === 63"
              />
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="w-full mt-8 flex justify-end">
      <el-button
        @click="modalSaveToggle = true"
        class="uppercase"
        size="large"
        type="primary"
        :icon="Select"
      >Salvar as alterações</el-button>
    </div>
    <el-dialog
      v-model="modalSaveToggle"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="30%"
      align-center
    >
      <el-result
        class="px-0 py-0"
        icon="warning"
        title="Confirma as alterações abaixo:"
      >
        <template #extra>
          <div v-for="item in listSave" :key="`save-${item.name}`" :class="`flex my-1 p-1 ${item.status === 1 ? 'bg-green-50 text-green-900' : 'bg-red-50 text-red-900'}`">
            <span class="w-full text-left truncate">{{ item.readable_name }}</span> 
            <i :class="item.status === 1 ? 'ri-check-line' : 'ri-close-line'" class="ml-2"></i>
          </div>
        </template>
      </el-result>
      <template #footer>
        <div class="flex justify-center">
          <el-button @click="modalSaveToggle = false">Cancelar</el-button>
          <el-button type="primary" @click="onSave">
            Confirmar
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Select } from '@element-plus/icons-vue'
import PermissionService from '@/services/permission.service'
import { permissionLdap } from '@/services/ldap/permission.ldap.service'
import { Groups, GROUP_DCC } from '@/const'
export default {
  name: 'PermissionsList',
  props: ['userDB', 'userLdap', 'infoUser', 'accessUser'],
  setup() {
    const activeMenu = ref(1)
    const modalSaveToggle = ref(false)
    const list = ref([])
    const listSave = ref([])
    const loading = ref(false)
    return {
      activeMenu,
      modalSaveToggle,
      list,
      listSave,
      loading,
      Groups,
      Select
    }
  },
  computed: {
    memberOfLdap() {
      if (this.userLdap && this.userLdap.memberOf) return this.userLdap.memberOf.flatMap((el) => {
        const permission = el.split(',')
        return permission[0].slice(3)
      }).sort()
      return false
    }
  },
  created() {
    this.permissionService = new PermissionService()
  },
  mounted() {
    console.log(this.userDB, this.userLdap, this.infoUser, this.accessUser)
    this.loading = true
    this.listAll()
  },
  watch: {
    userLdap(newValue, oldValue) {
      if(newValue) this.clear()
    },
    list(newValue) {
      if(newValue.length > 1) this.activeMenu = 0
    }
  },
  methods: {
    async listAll() {    
      await this.permissionService
        .all()
        .then((data) => {
          const permissionsUpdate = data.map((permission) => {
            if(this.memberOfLdap && this.memberOfLdap.includes(permission.name)) {
              return { ...permission, status: 1 }
            }
            return permission
          })

          if(GROUP_DCC.includes(Number(this.infoUser.unidade_id))) {
            if(this.accessUser?.accessLevel === 'CHEFE') {
              const groupParent = Groups.filter((group) => {
                if(this.accessUser.roles.find(({name}) => name.includes(group.id))) return group
              })
              this.list = groupParent.map((group) => {
                return { ...group, permissions: permissionsUpdate.filter((permission) => permission.name.includes(group.id)) }
              })
              return
            } 
            if(this.accessUser?.accessLevel === 'MANAGER') {
              this.list = Groups.map((group) => {
                return { ...group, permissions: permissionsUpdate.filter((permission) => permission.name.includes(group.id)) }
              })
              return
            }
          } else {
            this.list = [{
              id: 'pmdf_all',
              name: "Outras Unidades",
              permissions: permissionsUpdate.filter((permission) => permission.name.includes('pmdf_all'))
            }]
          }
          
        })
        .finally(() => this.loading = false)
    },
    actionPermission(el) {
      const list = this.listSave
      const permissionExist = list.filter((e) => el.id === e.id)
      if (permissionExist.length !== 0) {
        this.listSave = list.map((e) => {
          if (permissionExist.id === e.id) {
            return { ...el }
          }
          return e
        })
        return
      }
      this.listSave.push(el)
    },
    async onSave() {
      Promise.all([this.listSave.map((permission) => {
        if(permission.status === 1) {
          if(GROUP_DCC.includes(Number(this.infoUser.unidade_id))) {
            permissionLdap.addPermission({
              dn: this.userLdap.objectName,
              group: permission.name
            })
          }
          this.permissionService.add({ idUser: this.userDB.id, idRole: permission.id }) 
        }
        if(permission.status === 0) {
          if(GROUP_DCC.includes(Number(this.infoUser.unidade_id))) {
            permissionLdap.delPermission(`dn=${this.userLdap.objectName}&group=${permission.name}`)
          }
          this.permissionService.delete({ idUser: this.userDB.id, idRole: permission.id }) 
        }
      })])
      .then((res) => {
        console.log(res)
      })
      this.$emit('savePermissions')
      this.clear()
    },
    clear() {
      this.modalSaveToggle = false
      this.listSave = []
      this.loading = false
      this.activeMenu = 1
      this.listAll()
    }
  }

}
</script>
