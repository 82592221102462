import http from '@/plugins/axios'
import { notificationError } from '@/utils/notification'

class Department {
  constructor() {
    this.http = http
  }

  all() {
    return new Promise(async (resolve, reject) => {
      return await this.http.get('/department/all')
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }

  divisionAndSection(departmentId) {
    return new Promise(async (resolve, reject) => {
      return await this.http.post('/department/division', { departmentId })
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }
}

export default Department