import store from '@/store'
import http from '@/plugins/axios'
import { notificationError } from '@/utils/notification'

class User {
  constructor() {
    this.http = http
  }

  search(body) {
    return new Promise(async (resolve, reject) => {
      return await this.http.post('/user/search', body)
      .then(({ data }) => {
        store.dispatch('listUsers', data)
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }

  searchUserId(body) {
    return new Promise(async (resolve, reject) => {
      return await this.http.post('/user/id', body)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }

  perDepartment(body) {
    return new Promise(async (resolve, reject) => {
      return await this.http.post('/user/department', body)
      .then(({ data }) => {
        store.dispatch('listUsers', data)
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }

  info(matricula) {
    return new Promise(async (resolve, reject) => {
      return await this.http.post('/user/info', matricula)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }

  create(body) {
    return new Promise(async (resolve, reject) => {
      return await this.http.post('/user/create', body)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }

  update(body) {
    return new Promise(async (resolve, reject) => {
      return await this.http.put('/user', body)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }

  delete(idUser) {
    return new Promise(async (resolve, reject) => {
      return await this.http.delete(`/user/?idUser=${idUser}`)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }

  statusRegistration(body) {
    return new Promise(async (resolve, reject) => {
      return await this.http.post('/user/status-registration', body)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) =>  {
          notificationError('Erro!', `${err.response.data.Error}`)
          reject(err)
        })
    })
  }

  updateRegistration(body) {
    return new Promise(async (resolve, reject) => {
      return await this.http.post('/user/update-registration', body)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) =>  {
          notificationError('Erro!', `${err.response.data.Error}`)
          reject(err)
        })
    })
  }

  countUsers() {
    return new Promise(async (resolve, reject) => {
      return await this.http.get('/user/count')
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) =>  {
          notificationError('Erro!', `${err.response.data.Error}`)
          reject(err)
        })
    })
  }
}

export default User