import { ldap } from './settings'
import { notificationError } from '@/utils/notification'

class PermissionLdap {
  constructor() {
    this.ldapClient = ldap
  }

  listGroups(cn) {
    return new Promise(async (resolve, reject) => {
      return await this.ldapClient.post('/group/all', { cn })
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.msg}`)
        reject(err)
      })
    })
  }

  membersGroupLdap(cn) {
    return new Promise(async (resolve, reject) => {
      return await this.ldapClient.post('/group/members', { cn })
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.msg}`)
        reject(err)
      })
    })
  }

  addPermission(body) {
    return new Promise(async (resolve, reject) => {
      return await this.ldapClient.post('/group/add', body)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        Object.keys(err.response.data.msg).length > 0 && notificationError('Erro!', `${err.response.data.msg}`)
        reject(err)
      })
    })
  }

  delPermission(body) {
    return new Promise(async (resolve, reject) => {
      return await this.ldapClient.delete(`/group/?${body}`)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        Object.keys(err.response.data.msg).length > 0 && notificationError('Erro!', `${err.response.data.msg}`)
        reject(err)
      })
    })
  }
}

export const permissionLdap = new PermissionLdap()