import { ldap } from './settings'

class UserLdap {
  constructor() {
    this.ldapClient = ldap
  }

  getUserLdap(employeeId) {
    return new Promise(async (resolve, reject) => {
      return await this.ldapClient.post('/user/registration', { employeeId })
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        console.log(err.response.data)
        reject(false)
      })
    })
  }

  createUserLdap(data) {
    return new Promise(async (resolve, reject) => {
      return await this.ldapClient.post('/user/create', data)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        console.log(err.response.data)
        reject(false)
      })
    })
  }
  
  updateUserLdap(data) {
    return new Promise(async (resolve, reject) => {
      return await this.ldapClient.put('/user', data)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        console.log(err.response.data)
        reject(false)
      })
    })
  }

  directoryUpdateLdap(data) {
    return new Promise(async (resolve, reject) => {
      return await this.ldapClient.post('/user/directory', data)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        console.log(err.response.data)
        reject(false)
      })
    })
  }
}

export const userLdap = new UserLdap()