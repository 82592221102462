import http from '@/plugins/axios'
import { notificationError } from '@/utils/notification'

class Permission {
  constructor() {
    this.http = http
  }

  all() {
    return new Promise(async (resolve, reject) => {
      return await this.http.get('/permission/all')
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }

  user(body) {
    return new Promise(async (resolve, reject) => {
      return await this.http.post('/permission/user', body)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }

  add(body) {
    return new Promise(async (resolve, reject) => {
      return await this.http.post('/permission', body)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }

  delete(body) {
    const { idUser, idRole } = body
    return new Promise(async (resolve, reject) => {
      return await this.http.delete(`/permission/?idUser=${idUser}&idRole=${idRole}`, body)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) =>  {
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
      })
    })
  }
}

export default Permission