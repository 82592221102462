<template>
  <el-dialog
    v-model="updateVisible"
    :destroy-on-close="true"
    @closed="closeModal"
  >
    <template #header>
      <h1 class="mb-2 uppercase font-semibold text-gray-500">novo usuário</h1>
      <el-steps :active="activeSteps" simple>
        <el-step title="Dados" :icon="icon.Edit" />
        <el-step title="Permissões" :icon="icon.Unlock" />
      </el-steps>
      <h3 v-if="userExistsSgc === false" class="text-sm mt-2 text-center text-red-600">
        {{ `${activeSteps === 1 ? 'Informe os seguintes dados pessoais / profissionais e clicar em avançar.' : 'Ativar / desativar as permissões desejadas e clicar em salvar.'}` }}
      </h3>
    </template>
    <el-row v-if="activeSteps === 1">
      <el-row class="m-auto">
        <el-radio-group v-model="typeUser" @change="updatedTypeUser" size="large" fill="#22c55e" text-color="#ffffff">
          <el-radio-button class="uppercase" label="Policial" :value="1" />
          <el-radio-button class="uppercase" label="Civil" :value="2" />
        </el-radio-group>
      </el-row>
      <el-divider />
      <el-col v-if="typeUser === 'Policial'">
        <el-form
          :model="info"
          :rules="rulesForm"
          label-position="top"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="Matrícula">
                <el-input

                  @input="searchUser"
                  v-model="employeeId"
                  placeholder="Mínimo 7 dígitos"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-alert
            v-if="userExistsSgc && typeUser === 'Policial'"
            title="Usuário já cadastrado no SGC!"
            type="error"
            effect="dark"
            show-icon
          />
          <el-divider />
          <el-row  :gutter="20">
            <el-col :span="8">
              <el-form-item label="Posto / Graduação">
                <el-input
                  disabled
                  :value="`${info.user.quadro?.sigla || ''} ${info.user.posto_graduacao?.sigla || ''}`"
                />
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="Nome">
                <el-input
                  disabled
                  v-model="info.user.nome_completo"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider />
          <el-row justify="space-between" :gutter="20">
            <el-col :span="groupDcc.includes(info.user.unidade_id)? 6 : 24">
              <el-form-item label="Unidade">
                <el-input
                  class="w-full"
                  disabled
                  v-model="info.user.unidade_policiais_unidade_idTounidade.sigla"
                />
              </el-form-item>
            </el-col>
            <el-col v-if="groupDcc.includes(info.user.unidade_id)" :span="9">
              <el-form-item label="Divisão">
                <el-col :span="24">    
                  <el-select
                    class="w-full"
                    v-model="divisionSelected"
                    :disabled="divisionDisabled"
                    placeholder="Selecione a divisão"
                  >
                    <el-option
                      v-for="div in info.division"
                      :key="div.id"
                      :label="div.sigla"
                      :value="div.id" 
                    />
                  </el-select>
                </el-col>
              </el-form-item>
            </el-col>
            <el-col v-if="groupDcc.includes(info.user.unidade_id)" :span="9">
              <el-form-item label="Seção">
                <el-col :span="24">
                  <el-select
                    class="w-full"
                    v-model="secaoSelected"
                    :disabled="!contentDivisionSelected"
                    @change="updateSection"
                    placeholder="Selecione a seção"
                  >
                    <el-option
                      v-for="sec in contentDivisionSelected?.secao"
                      :key="sec.id"
                      :label="sec.sigla"
                      :value="sec.id" 
                    />
                  </el-select>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider />
          <el-row :gutter="20">
            <el-col :span="14">
              <el-form-item label="E-mail">
                <el-input
                  v-model="email_particular"
                  :disabled="disabledMailPhone"
                  :rules="[
                    { required: true }
                  ]"
                  placeholder="Informe o melhor e-mail"
                />
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="Telefone">
                <el-input
                  v-model="telefone_numero"
                  :disabled="disabledMailPhone"
                  :rules="[
                    { required: true }
                  ]"
                  v-mask="['(##) #####-####', '(##) ####-####']"
                  placeholder="Informe o telefone"
                  type="tel"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider />
          <el-row class="w-full flex justify-center mt-5">
            <el-form-item>
              <el-row class="flex gap-8">
                <el-button @click="activeSteps = 1">Voltar</el-button>
                <el-button
                  @click="onSubmit"
                  :disabled="!email_particular && !telefone_numero"
                  :loading="loading"
                  type="primary"
                >Avançar</el-button>
              </el-row>
            </el-form-item>
          </el-row>
        </el-form>
      </el-col>
      <el-col v-if="typeUser === 'Civil'">
        <el-form
          :model="info"
          :rules="rulesForm"
          label-position="top"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="Matrícula">
                <el-input

                  @input="searchUser"
                  v-model="employeeId"
                  placeholder="Mínimo 7 dígitos"
                />
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="Nome">
                <el-input
                  v-model="userCivil.name"
                  :disabled="updateUserPmExistsSgc"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-alert
            v-if="userExistsSgc || updateUserPmExistsSgc"
            :title="(userExistsSgc && 'Usuário já cadastrado no SGC!') || (updateUserPmExistsSgc && 'Usuário PM cadastrado com essa matrícula!')"
            type="error"
            effect="dark"
            show-icon
          />
          <el-divider />
          <el-row  :gutter="20">
            <el-col :span="12">
              <el-form-item label="CPF">
                <el-input
                  v-model="userCivil.cpf"
                  :disabled="updateUserPmExistsSgc"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="RG">
                <el-input
                  v-model="userCivil.rg"
                  :disabled="updateUserPmExistsSgc"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider />
          <el-row justify="start" align="middle" :gutter="20">
            <el-col :span="10">
              <el-form-item label="Unidade">
                <el-select
                  v-model="userCivil.unidade_id"
                  :disabled="updateUserPmExistsSgc"
                  filterable
                  class="w-full select__department py-2"
                  placeholder="Unidades"
                  size="default"
                >
                  <el-option
                    v-for="item in listDepartments"
                    :key="item.id"
                    :label="item.nome + ' - ' + item.sigla"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="groupDcc.includes(userCivil.unidade_id)" :span="7">
              <el-form-item label="Divisão">
                <el-col :span="24">    
                  <el-select
                    class="w-full"
                    v-model="divisionSelected"
                    :disabled="!userCivil.unidade_id"
                    placeholder="Selecione a divisão"
                  >
                    <el-option
                      v-for="div in listDivision"
                      :key="div.id"
                      :label="div.sigla"
                      :value="div.id" 
                    />
                  </el-select>
                </el-col>
              </el-form-item>
            </el-col>
            <el-col v-if="groupDcc.includes(userCivil.unidade_id)" :span="7">
              <el-form-item label="Seção">
                <el-col :span="24">
                  <el-select
                    class="w-full"
                    v-model="secaoSelected"
                    :disabled="!divisionSelected"
                    @change="updateSection"
                    placeholder="Selecione a seção"
                  >
                    <el-option
                      v-for="sec in listSecao"
                      :key="sec.id"
                      :label="sec.sigla"
                      :value="sec.id" 
                    />
                  </el-select>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider />
          <el-row :gutter="20">
            <el-col :span="14">
              <el-form-item label="E-mail">
                <el-input
                  v-model="email_particular"
                  :disabled="updateUserPmExistsSgc"
                  :rules="[
                    { required: true }
                  ]"
                  placeholder="Informe o melhor e-mail"
                />
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="Telefone">
                <el-input
                  v-model="telefone_numero"
                  :disabled="updateUserPmExistsSgc"
                  :rules="[
                    { required: true }
                  ]"
                  v-mask="['(##) #####-####', '(##) ####-####']"
                  placeholder="Informe o telefone"
                  type="tel"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider />
          <el-row class="w-full flex justify-center mt-5">
            <el-form-item>
              <el-row class="flex gap-8">
                <el-button @click="activeSteps = 1">Voltar</el-button>
                <el-button
                  v-if="typeUser === 'Policial'"
                  @click="onSubmit"
                  :disabled="!email_particular && !telefone_numero"
                  :loading="loading"
                  type="primary"
                >Avançar</el-button>
                <el-button
                  v-else-if="typeUser === 'Civil'"
                  @click="onSubmit"
                  :disabled="!userCivil.cpf && !userPmExistsSgc && !userExistsSgc && !telefone_numero && !email_particular"
                  :loading="loading"
                  type="primary"
                >Avançar</el-button>
              </el-row>
            </el-form-item>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <el-row class="flex justify-center" v-else-if="activeSteps === 2">
      <div class="w-11/12">
        <PermissionList
          @savePermissions="updatePermissions"
          :userDB="info.userExistsSgc"
          :userLdap="dataOfUserLdap"
          :infoUser="typeUser === 'Policial' ? info.user : userCivil"
          :accessUser="{accessUser}"
        />
      </div>
    </el-row>
  </el-dialog>
</template>

<script>
import { Edit, Unlock, User } from '@element-plus/icons-vue'
import PermissionList from '@/components/permission/PermissionsList.vue'

import UserService from '@/services/user.service'
import DepartmentService from '@/services/department.service'
import { userLdap } from '@/services/ldap/user.ldap.service'
import { notificationSuccess } from '@/utils/notification'
import { GROUP_DCC } from '@/const'

export default {
  name: 'ModalCreateUser',
  components: {
    PermissionList
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    departments: {
      type: Object
    },
    accessUser: {
      type: Object
    }
  },
  data() {
    return {
      icon: {
        Edit,
        Unlock,
        User
      },
      loading: false,
      activeSteps: 1,
      disabledMailPhone: true,
      divisionDisabled: true,
      employeeId: null,
      divisionSelected: null,
      secaoSelected: null,
      listDivision: [],
      listSecao: [],
      userCivil: {
        name: null,
        cpf: null,
        rg: null,
        unidade_id: null
      },
      email_particular: null,
      telefone_numero: null,
      userExistsSgc: null,
      userPmExistsSgc: false,
      info: {
        user: {
          unidade_policiais_unidade_idTounidade: {
            sigla: null
          }
        },
        division: [],
      },
      rulesForm: {
        employeeId: [
          {
            required: true,
            message: 'campo obrigatório',
            trigger: 'change',
          },
        ],
      },
      infoLdap: null,
      typeUser: 'Policial',
      listDepartments: []
    }
  },
  computed: {
    updateVisible() {
      return this.visible
    },
    contentDepartmentSelected() {
      if (this.typeUser === 'Civil') return this.listDepartments.find(el => el.id === this.userCivil.unidade_id)
    },
    contentDivisionSelected() {
      if (this.divisionSelected) {
        if (this.typeUser === 'Policial') return this.info.division.find(el => el.id === this.divisionSelected)
        if (this.typeUser === 'Civil') return this.listDivision.find(el => el.id === this.divisionSelected)
      }
    },
    contentSectionSelected() {
      if (this.secaoSelected) {
        if (this.typeUser === 'Policial') return this.contentDivisionSelected.secao.find(el => el.id === this.secaoSelected)
        if (this.typeUser === 'Civil') return this.contentDivisionSelected.secao.find(el => el.id === this.secaoSelected)
      }
    },
    groupDcc() {
      return GROUP_DCC
    },
    dataOfUserLdap() {
      return this.infoLdap
    },
    updateUserPmExistsSgc() {
      return this.userPmExistsSgc
    },
    updateUnidadeId() {
      return this.userCivil.unidade_id
    }

  },
  created() {
    this.userService = new UserService()
    this.departmentService = new DepartmentService()
  },
  mounted() {
    if (this.departments) this.listDepartments = this.departments
    else {
      this.departmentService
        .all()
        .then((data) => {
          this.listDepartments = data
        })
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true
      let body = {}
      if (this.typeUser === 'Policial') {
        const name = this.info.user.nome_completo.split(' ')
        if (this.groupDcc.includes(this.info.user.unidade_id)) {
          body = {
            username: this.info.user.cpf,
            displayname: `${this.info.user.posto_graduacao.sigla} ${this.info.user.quadro.sigla} ${this.info.user.nome_de_guerra}`,
            email: `${this.info.user.login_rede_pmdf}@pm.df.gov.br`,
            description: `${this.info.user.unidade_policiais_unidade_idTounidade.sigla} - ${this.contentDivisionSelected.sigla}`,
            givenname: name[0],
            physicaldeliveryofficename: this.contentDivisionSelected.sigla,
            sn: name[name.length - 1],
            name: this.info.user.nome_completo,
            department: this.info.user.unidade_policiais_unidade_idTounidade.sigla,
            company: "PMDF",
            title: `${this.info.user.posto_graduacao.sigla} ${this.info.user.quadro.sigla}`,
            employeeid: this.info.user.matricula,
            employeenumber: this.info.user.cpf,
            email_particular: this.email_particular,
            secao_id: this.secaoSelected,
            divisao_id: this.divisionSelected,
            unidade_id: this.info.user.unidade_id,
            empresa_id: 1,
            telefone_numero: this.telefone_numero,
            rg_numero: this.info.user.rg_numero,
            nome_guerra: this.info.user.nome_de_guerra
          }
        } else {
          body = {
            username: this.info.user.cpf,
            displayname: `${this.info.user.posto_graduacao.sigla} ${this.info.user.quadro.sigla} ${this.info.user.nome_de_guerra}`,
            email: `${this.info.user.login_rede_pmdf}@pm.df.gov.br`,
            description: `${this.info.user.unidade_policiais_unidade_idTounidade.sigla}`,
            givenname: name[0],
            physicaldeliveryofficename: 'PMDF',
            sn: name[name.length - 1],
            name: this.info.user.nome_completo,
            department: this.info.user.unidade_policiais_unidade_idTounidade.sigla,
            company: "PMDF",
            title: `${this.info.user.posto_graduacao.sigla} ${this.info.user.quadro.sigla}`,
            employeeid: this.info.user.matricula,
            employeenumber: this.info.user.cpf,
            email_particular: this.email_particular,
            secao_id: 5,
            divisao_id: 3,
            unidade_id: this.info.user.unidade_id,
            empresa_id: 1,
            telefone_numero: this.telefone_numero,
            rg_numero: this.info.user.rg_numero,
            nome_guerra: this.info.user.nome_de_guerra
          }
        }
      }
      if (this.typeUser === 'Civil') {
        const name = String(this.userCivil.name).toUpperCase().split(' ')
        body = {
          username: this.userCivil.cpf,
          displayname: `${this.employeeId} ${String(this.userCivil.name).toUpperCase()}`,
          email: `${this.userCivil.cpf}@pm.df.gov.br`,
          description: `${this.contentDepartmentSelected.sigla} - ${this.contentDivisionSelected ? this.contentDivisionSelected.sigla : 'SJD'}`,
          givenname: name[0],
          physicaldeliveryofficename: `${this.contentSectionSelected ? this.contentSectionSelected.sigla : 'SJD'}`,
          sn: name[name.length - 1],
          name: `${String(this.userCivil.name).toUpperCase()}`,
          department: this.contentDepartmentSelected.sigla,
          company: "PMDF",
          title: "FUNC. CIVIL",
          employeeid: this.employeeId,
          employeenumber: this.userCivil.cpf,
          email_particular: this.email_particular,
          secao_id: this.secaoSelected ? this.secaoSelected : 5,
          divisao_id: this.secaoSelected ? this.divisionSelected : 3,
          unidade_id: this.userCivil.unidade_id,
          empresa_id: 1,
          telefone_numero: this.telefone_numero,
          rg_numero: this.userCivil.rg,
          nome_guerra: "CIVIL"
        }
      }
      await this.userService
        .create({ ...body })
        .then(async (data) => {
          if(this.groupDcc.includes(body.unidade_id)) {
            await userLdap.createUserLdap({ data: 
              {
                samaccountname: body.username,
                cn: body.displayname,
                displayname: body.displayname,
                mail: body.email,
                description: body.description,
                givenname: body.givenname,
                physicaldeliveryofficename: body.physicaldeliveryofficename,
                sn: body.sn,
                name: body.name,
                department: body.department,
                info: 'info',
                company: body.company,
                title: body.title,
                employeeid: body.employeeid,
                employeenumber: body.employeenumber,
                othermailbox: body.email_particular,
                userAccountControl: 544, 
                departmentnumber: body.unidade_id,
                roomnumber: body.divisao_id,
                mobile: body.telefone_numero,
                objectclass: "User"
              }
            })
          }
          this.activeSteps = 2
          notificationSuccess('Sucesso!', `O usuário ${data.displayname} foi criado com sucesso!`, 5000, false)
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.searchUser()
          this.loading = false
        })

    },
    async searchUser() {
      this.divisionDisabled = true
      this.disabledMailPhone = true
      if (this.employeeId.length >= 7) { 
        if (this.typeUser === 'Policial') {
          await this.userService
            .info({ matricula: this.employeeId })
            .then(async (data) => {
              this.info = data
              this.office = data.user
              if (data.userExistsSgc) this.userExistsSgc = true
              else {
                this.userExistsSgc = false
                this.divisionDisabled = false
              }
              if (GROUP_DCC.includes(data.user.unidade_id)) {
                const dataLdap = await userLdap.getUserLdap(this.employeeId)
                if(dataLdap) {
                  const { objectName, attributes } = dataLdap
                  this.infoLdap = { objectName, attributes }
                } else this.infoLdap = false
              }
            })
            .catch((err) => {
              console.log(err);
            })
        }

        if (this.typeUser === 'Civil') {
          this.userService
            .searchUserId({ employeeId: this.employeeId.trim() })
            .then(async (data) => {
              const userPm = data.find(({ title }) => title !== 'FUNC. CIVIL')
              const user = data.find(({ title }) => title === 'FUNC. CIVIL')
              this.info.userExistsSgc = { ...user }
              userPm ? this.userPmExistsSgc = true : this.userPmExistsSgc = false
              if (user) {
                this.userExistsSgc = true
                this.info.user = user
              } else {
                this.userExistsSgc = false
                this.info.user = null
              }
              if (GROUP_DCC.includes(user.unidade_id)) {
                const dataLdap = await userLdap.getUserLdap(this.employeeId)
                if(dataLdap) {
                  const { objectName, attributes } = dataLdap
                  this.infoLdap = { objectName, attributes }
                } else this.infoLdap = false
              }
            })
        }
      }
    },
    updateSection() {
      this.disabledMailPhone = false
    },
    updatePermissions() {
      notificationSuccess('Sucesso!', `As permissões foram atualizadas com sucesso!`, 5000, false)
      this.$emit('createSuccess')
      this.closeModal()      
    },
    clear() {
      this.activeSteps = 1,
      this.employeeId = null,
      this.divisionSelected = null,
      this.secaoSelected = null, 
      this.divisionDisabled = true,
      this.disabledMailPhone = true,
      this.email_particular = null,
      this.telefone_numero = null,
      this.userExistsSgc = null,
      this.userPmExistsSgc = null,
      this.info = {
        user: {
          unidade_policiais_unidade_idTounidade: {
            sigla: null
          }
        },
        division: []
      },
      this.dataOfUserLdap = null
    },
    closeModal() {
      this.$emit('close')
      this.clear()
    },
    updatedTypeUser() {
      this.clear()
      if (this.typeUser === 'Civil') {

      }
    }
  },
  watch: {
    info(newValue) {
      if(this.userExistsSgc === false && !this.groupDcc.includes(newValue.user.unidade_id)) this.updateSection()
    },
    updateUnidadeId(newValue) {
      if (this.groupDcc.includes(newValue)) this.departmentService
        .divisionAndSection(newValue)
        .then(({ divisao }) => {
          this.listDivision = divisao
        })
    },
    divisionSelected(newValue) {
      const { secao } = this.listDivision.find(({ id }) => newValue === id)
      this.listSecao = secao
    },
    typeUser() {
      this.clear()
    }
  }
}
</script>
