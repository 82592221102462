const GROUP_DCC = [622, 10036, 673, 710]

const Keys = {
  "dcc.gab.equipe":	"Gab. Corregedor - Equipe",
  "dcc.gab.atj":	"Gab. Corregedor - Assessoria Técnico Jurídica",
  "dcc.gab.chefia":	"Gab. Corregedor - Chefe de Gabinete",
  "dcc.divcor.spe":	"DivCor - Seção de Procedimentos Éticos",
  "dcc.conselhos.cpd1":	"Conselhos - Conselho Permanente de Disciplina I",
  "dcc.conselhos.cpd2":	"Conselhos - Conselho Permanente de Disciplina II",
  "dcc.conselhos.secretaria":	"Conselhos - Secretaria dos Conselhos",
  "dcc.dic.chefia":	"DIC - Chefia",
  "dcc.dic.nucrim":	"DIC - Núcleo de Criminalística",
  "dcc.dic.sadmin":	"DIC - Seção Administrativa",
  "dcc.dic.sint":	"DIC - Seção de Inteligência",
  "dcc.dic.sop":	"DIC - Seção Operacional",
  "dcc.dic.stic":	"DIC - Seção de Tecnologia da Informação e Comunicação",
  "dcc.divcor.chefia":	"DivCor - Chefia",
  "dcc.divcor.scj":	"DivCor - Seção de Convocação Judicial",
  "dcc.divcor.secor":	"DivCor - Seção de Correição",
  "dcc.divcor.sps":	"DivCor - Seção de Procedimentos Sumários",
  "dcc.divcor.ssind":	"DivCor - Seção de Sindicâncias",
  "dcc.dpjm.chefia":	"DPJM - Chefia",
  "dcc.dpjm.spj":	"DPJM - Seção de Procedimentos Judiciais",
  "dcc.gab.corregedorAdjunto":	"Gab. Corregedor - Corregedor Adjunto",
  "dcc.gab.corregedorGeral":	"Gab. Corregedor - Corregedor-Geral",
  "dcc.gab.secretaria":	"Gab. Corregedor - Secretária",
  "gs.admin.geral":	"Adminstrador Geral da rede do DCC",
  "pmdf_all.sjds":	"Seção de Justiça e Disciplina",
  "dcc.divcor.ssind.chefia":	"DivCor - Seção de Sindicâncias - Chefia",
  "dcc.divcor.sps.chefia":	"DivCor - Seção de Procedimentos Sumários - Chefia",
  "dcc.divcor.secor.chefia":	"DivCor - Seção de Correição - Chefia",
  "dcc.dpjm.spj.chefia":	"DPJM - Seção de Procedimentos Judiciais - Chefia",
  "dcc.dpjm.ci":	"DPJM - Central de Inquéritos",
  "dcc.dpjm.stro.escrivao":	"DPJM - STRO - Escrivão",
  "dcc.dpjm.stro.delegado":	"DPJM - STRO - Delegado",
  "dcc.dpjm.equipe":	"DPJM - Equipe",
  "pmdf_all.comandantes":	"Comandantes, Chefes e Diretores de UPMs da PMDF",
  "dcc.dic.certidao":	"DIC - Responsáveis por emissões de certidões",
  "dcc.auditoria.chefia":	"Auditoria - Chefia",
  "dcc.auditoria.ctce":	"Auditoria - Central de Tomada de Contas Especial",
  "dcc.auditoria.sad":	"Auditoria - Seção AD",
  "dcc.auditoria.saf":	"Auditoria - Seção de AF",
  "dcc.auditoria.sapp":	"Auditoria - Seção de APP",
  "dcc.auditoria.ssdcf":	"Auditoria - Subseção de DCF",
  "tjdft.all":	"TJDFT",
  "dcc.divpol.spessoal":	"DivPol - Seção de Pessoal",
  "dcc.divpol.arquivo":	"DivPol - Arquivo",
  "dcc.divpol.cartorio":	"DivPol - Cartório",
  "dcc.divpol.chefia":	"DivPol - Chefia",
  "dcc.divpol.guarda":	"DivPol - Guarda",
  "dcc.divpol.sece":	"DivPol - Seção de Controle de Escalas",
  "dcc.divpol.slog":	"DivPol - Seção de Logística",
  "pmdf_all.convocacaoJudicial":	"Todas as seções administrativas da PMDF",
  "dcc.auditoria.ctce.ssade":	"Auditoria - CTCE - Subseção ADE",
  "dcc.auditoria.ctce.ssicit":	"Auditoria - CTCE - Subseção ICIT",
  "pmdf_all.encarregados":	"Encarregado de Procedimento",
  "pmdf_all.escrivaes":	"Escrivão de Procedimento",
  "dcc.acesso_ipm":	"Acesso de Consulta aos IPMs",
  "dcc.dic.parecer":	"DIC - Responsáveis por Parecer Porte de Arma",
  "proxy.acesso.livre": "Proxy Livre"
}

const Groups = [
  { id: "auditoria.", name: "Auditoria" },
  { id: "conselhos.", name: "Conselhos" },
  { id: "dic.", name: "DAT" },
  { id: "divcor.", name: "DIVCOR" },
  { id: "divpol.", name: "SAD" },
  { id: "dpjm.", name: "DPJM" },
  { id: "gab.", name: "Gabinete" },
  { id: "pmdf_all.", name: "Outras Unidades" },
  { id: "proxy.", name: "Diversas" },
]

const ACCESS_PROFILES = {
  'stic': 'MANAGER',
  'divpol.chefia': 'MANAGER',
  'divpol.spessoal': 'MANAGER',
  'chefia': 'CHEFE'
}

export { GROUP_DCC, Keys, Groups, ACCESS_PROFILES }